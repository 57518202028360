/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import classes from './InstallationDetails.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import captureImg from '../../../assets/capture_vsg_ID.svg';
import upload from '../../../assets/download_vsg_ID.svg';
import uploadDisabled from '../../../assets/dashboardICons/upload_disabled_grayBG.svg';
import CameraCaptureDisabled from '../../../assets/dashboardICons/CameraCapture_disabled_grayBG.svg';
import switchCamera from '../../../assets/switchCamera.svg';
import Webcam from 'react-webcam';
import dayjs, { Dayjs } from 'dayjs';
import { MultiDetails } from './MultiDetails';
import { getUserSFDetails } from 'utils/getUserAccount';
import { dashboardQuery } from '../../dahboard/dashboard.query';
import { spinnerStore } from '@Store/spinnerStore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '@Components/Button';
import { toast } from 'react-toastify';
function InstallationDetails() {
  const webcamRef = useRef(null); // create a webcam reference
  const popup = useRef();
  const [imgSrc, setImgSrc] = useState(); // initialize it
  //alert for refresh confirmation
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const {
    setInstallationDetails,
    installationDetails,
    setStepPrev,
    setNextButton,
    setIDCheckedValue,
    isIdNumCheck,
    setInstallationDetailsData,
    installationDateValue,
    setInstallationDateDetails,
  } = installationStore();

  const [imgCheck, setImgCheck] = React.useState(false);
  const [uploadCheck, setUploadCheck] = useState(false);
  const [dataLength, setDataLength] = useState(false);

  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');

  const email = getUserSFDetails().email;
  const { data, isLoading } = dashboardQuery.useMultiProducts(email, ids, true);

  const { setSpinner, isActive } = spinnerStore();
  const [selectedImage, setSelectedImage] = useState();
  const [open, setOpen] = useState(true);
  const [verify, setVerify] = useState(false);
  // const [selectedWarrantyData, setselectedWarrantyData] = useState();
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [data]);
  console.warn(installationDateValue, 'setInstallationDateDetails');
  const generateExtenedWarrantyData = (initData) => {
    const updatedData = initData.map((item) => {
      if (installationDetails) {
        const existData = installationDetails.find((existingItem) => {
          return (
            existingItem.equipmentNumber.toLowerCase() ===
            item?.equipmentNumber.toLowerCase()
          );
        });
        if (existData) {
          return existData;
        }
      }
      const newData = {
        productCode: item?.productCode,
        equipmentNumber: item?.equipmentNumber,
        equipmentId: item?.equipmentId,
        hasextendedWarranty: false,
      };
      return newData;
    });
    return updatedData;
  };

  const [warrayntDATA, setWarrantyDATA] = useState(
    generateExtenedWarrantyData(data),
  );
  const [checked, setChecked] = useState(checkingData());
  function checkingData() {
    if (installationDetails) {
      const existingDatachecking = installationDetails.filter((item) => {
        return item.image ?? false;
      });
      return existingDatachecking.length > 0;
    }
    return false;
  }

  useEffect(() => {
    if (data?.length > 1) {
      setDataLength(false);
    } else {
      setDataLength(true);
    }
  }, [data]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setChecked(true);
    } else {
      // setWarrantyDATA(generateExtenedWarrantyData(data));
      // setWarrantyDATA('');
      setInstallationDetails('');
      setInstallationDetailsData('');
      setTimeout(() => {
        setWarrantyDATA(generateExtenedWarrantyData(data));
      }, 200);
      setVerify(false);
      setChecked(false);
    }
    setOpen(e.target.checked);
    setIDCheckedValue(true);
  };

  useEffect(() => {
    setIDCheckedValue(checked);
  }, [checked]);

  // const multiviewData = [data];
  const hasextendedWarranty = warrayntDATA[0].hasextendedWarranty ?? false;
  const identificationNum = warrayntDATA[0].identificationNum ?? '';
  const imageData = warrayntDATA[0].image ?? false;
  console.warn(imageData, 'imageData');
  const isEnabled = !hasextendedWarranty;
  const singleData = [];
  const [isIdNumValid, setIsIdNumValid] = useState(false);
  const [isValid, setIsValid] = useState(true); // To keep track of validity
  // Regex pattern for validation
  const regex = /^rl[a-zA-Z0-9]{2,10}$/i;
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value !== '') {
      // const validation = regex.test(value);
      if (regex.test(value)) {
        setIsValid(true);
        setIsIdNumValid(true);
      } else {
        setIsIdNumValid(false);
        setIsValid(false);
      }
    } else {
      setIsValid(true);
      setIsIdNumValid(false);
    }
    if (warrayntDATA[0].identificationNum || warrayntDATA[0].image) {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: event.target.value,
        image: warrayntDATA[0].image,
        hasextendedWarranty: isEnabled,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    } else {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: event.target.value,
        image: imageData,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    // Update timestamp every second
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const [uploadedImg, setUploadedImg] = useState();
  const handleupload = async (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore\
      setUploadCheck(false);
      return;
    } else {
      const selectedImagedetails = e.target.files[0];
      if (!selectedImagedetails.type.startsWith('image/')) {
        toast.error('Please select an image file!', {
          position: toast.POSITION.TOP_CENTER,
        });
        //  setFileSize(null);
        return;
      }
      // Validate the file size (5 MB = 5 * 1024 * 1024 bytes)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (selectedImagedetails.size > maxSizeInBytes) {
        toast.error(
          'The selected warranty image exceeds the 5 MB size limit. Please choose a smaller file and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        //  setFileSize(null);
        return;
      }
      setUploadedImg(URL.createObjectURL(e.target.files[0]));
      const uploadImgURL = await convertBase64(selectedImagedetails);
      const imageSplit = uploadImgURL.split(',');
      const namesplit = selectedImagedetails.name.split('.');
      let fileName =
        namesplit[0] + warrayntDATA[0].equipmentNumber + '_' + timestamp;
      namesplit.forEach((item, index) => {
        if (index > 0) {
          fileName = fileName + '.' + item;
        }
      });
      const imageDetails = {
        name: fileName,
        size: selectedImagedetails.size,
        type: selectedImagedetails.type,
        uri: imageSplit[1],
      };
      setSelectedImage(true);
      if (warrayntDATA) {
        if (warrayntDATA[0].identificationNum) {
          const singleViewData = {
            productCode: warrayntDATA[0].productCode,
            equipmentNumber: warrayntDATA[0].equipmentNumber,
            equipmentId: warrayntDATA[0].equipmentId,
            identificationNum: warrayntDATA[0].identificationNum,
            image: imageDetails,
            hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
          };
          singleData.push(singleViewData);
          setWarrantyDATA(singleData);
        } else {
          const singleViewData = {
            productCode: warrayntDATA[0].productCode,
            equipmentNumber: warrayntDATA[0].equipmentNumber,
            equipmentId: warrayntDATA[0].equipmentId,
            identificationNum: identificationNum,
            image: imageDetails,
            hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
          };
          singleData.push(singleViewData);
          setWarrantyDATA(singleData);
        }
      }
      setUploadCheck(true);
    }
  };
  const removeSelectedImage = (e) => {
    e.target.value = '';
    const singleViewData = {
      productCode: warrayntDATA[0].productCode,
      equipmentNumber: warrayntDATA[0].equipmentNumber,
      equipmentId: warrayntDATA[0].equipmentId,
      identificationNum: warrayntDATA[0].identificationNum,
      image: false,
      hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
    };
    singleData.push(singleViewData);
    setWarrantyDATA(singleData);
    setUploadCheck(false);
    setSelectedImage(false);
    setImgSrc(null);
    setImgCheck(false);
  };
  const removeCaptureImage = () => {
    setImgSrc(null);
    setImgCheck(false);
    setSelectedImage(false);
    // setUploadCheck(false);
  };
  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);
  // create a capture function
  const capture = () => {
    setOpenDailogue(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setUploadedImg(imageSrc);
    const imageSplit = imageSrc.split(',');
    // const uploadImgURL = URL.createObjectURL(imageSrc);
    const imageDetails = {
      name: warrayntDATA[0].equipmentId + '_' + timestamp + '.jpeg',
      size: webcamRef.current.props.screenshotQuality,
      type: webcamRef.current.props.screenshotFormat,
      uri: imageSplit[1],
    };
    if (warrayntDATA[0].identificationNum) {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: warrayntDATA[0].identificationNum,
        image: imageDetails,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    } else {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: identificationNum,
        image: imageDetails,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    }

    const captureImg = imageSrc ? true : false;
    setSelectedImage(captureImg);
    setImgSrc(captureImg);
  };
  const captureTheimage = () => {
    setOpenDailogue(true);
    setImgCheck(true);
  };
  const formatDate = (date) => date.toISOString().split('T')[0];
  const currentDate = dayjs();
  const formattedDate = currentDate.format('YYYY-MM-DD');
  const isEmpty = (installationDateValue) =>
    JSON.stringify(installationDateValue) === '{}';
  const [value, setValue] = useState(
    !isEmpty(installationDateValue)
      ? dayjs(installationDateValue).toISOString()
      : dayjs(new Date()).toISOString(),
  );
  // const [value, setValue] = useState(initDataValue);
  // var test = formatDate(new Date(value));
  useEffect(() => {
    localStorage.setItem('InstalDateValue', value);
    localStorage.setItem('extendedWarrantyCheck', checked);
  }, [value]);
  const onDoneClick = (selectedWarranty) => {
    const selectedDetails = selectedWarranty.filter((item) => {
      return item.hasextendedWarranty;
    });
    setVerify(selectedDetails.length > 0);
    if (selectedDetails.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    console.warn(checked, 'checkedChecking786');
    setWarrantyDATA(selectedWarranty);
    console.warn(warrayntDATA, 'warrantySelectedData123');
  };
  const onCancelClick = () => {
    const selectedDetails = warrayntDATA.filter((item) => {
      return item.hasextendedWarranty;
    });
    setVerify(selectedDetails.length > 0);
    if (selectedDetails.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
      // handleChange();
    }
  };
  useEffect(() => {
    if (
      ((isIdNumValid || warrayntDATA[0].identificationNum) &&
        isValid &&
        warrayntDATA[0].image) ||
      verify ||
      !checked
    ) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  }, [warrayntDATA, verify, checked, isValid]);

  useEffect(() => {
    // setInstallationDetailsData(warrayntDATA);
    console.warn(warrayntDATA, 'warrayntDATA');
  }, [warrayntDATA]);
  const handleModify = (e) => {
    // setChecked(true);
    //setOpen(true);
    const popupRef = popup.current;

    if (popupRef) {
      popupRef.showView(warrayntDATA);
    }
  };
  const getStartMonthsAgo = () => {
    // Create a new Date object for the current date
    const now = new Date();
    // Calculate the date six months ago
    const MonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
    return formatDate(MonthsAgo);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDailogue, setOpenDailogue] = useState(false);
  const handleClose1 = () => {
    setOpenDailogue(false);
  };
  useEffect(() => {
    setInstallationDateDetails(value);
  }, [value]);
  const handleNextClick = (e) => {
    setInstallationDetails(warrayntDATA, true);
  };
  const handlePrevClick = () => {
    setStepPrev();
    setWarrantyDATA('');
    setInstallationDetails('');
    setInstallationDetailsData('');
    setInstallationDateDetails({});
  };
  const [imageOpen, setImageOpen] = React.useState(false);

  const handleClickOpen = () => {
    setImageOpen(true);
  };
  const handleClose = () => {
    setImageOpen(false);
  };

  useClickHandlers(handleNextClick, handlePrevClick);
  return (
    <div className={classes.installationDetailCont}>
      <div className={classes.installationDetails}>
        <div className={classes.dateContainer}>
          <p className={classes.installationDate}>Installation Date</p>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className={classes.dateSelect}
          >
            <DatePicker
              sx={{
                color: '#D2D2D2',
                borderRadius: '2px',
                borderWidth: '1px',
                borderColor: '#D2D2D2',
                border: '1px solid',
                backgroundColor: '#fff',
                minWidth: '280px',
                maxWidth: '420px',
                width: '100%',
                // height: '38px',
              }}
              views={['year', 'month', 'day']}
              defaultValue={dayjs(value)}
              minDate={dayjs(getStartMonthsAgo())}
              maxDate={dayjs(new Date())}
              selected={value}
              onChange={(value) => setValue(value.toISOString())}
            />
          </LocalizationProvider>
          {/* <p>Stored value: {value == null ? 'null' : value.format()}</p> */}
        </div>
        <div className={classes.checkboxCont}>
          <input
            value="test"
            className={classes.checkboxStyles}
            type="checkbox"
            onChange={handleChange}
            checked={checked}
          />{' '}
          <p className={classes.extendWaranty}>Extended Warranty</p>
          {verify ? (
            <div>
              <button onClick={handleModify} className={classes.verifyButton}>
                Verify/Modify
              </button>
            </div>
          ) : null}
          {checked ? (
            <div>
              {dataLength ? (
                <>
                  <>
                    <div className={classes.identificationDetails}>
                      <p>Identification number</p>
                      <input
                        type="text"
                        onChange={handleInputChange}
                        value={warrayntDATA[0].identificationNum}
                        style={{
                          borderColor: isValid ? '#D2D2D2' : '#CE1F1F',
                          color: isValid ? '#000' : '#CE1F1F',
                        }}
                      />{' '}
                      {!isValid && (
                        <p className={classes.invalidInput}>
                          The warranty certificate ID is invalid. It must be
                          between 4 and 12 characters long, start with "RL," and
                          contain only alphanumeric characters. Please correct
                          the ID and try again.
                        </p>
                      )}
                    </div>
                    <div className={classes.warrantyDiv}>
                      <p>Please Upload/Capture warranty certificate </p>
                      <input
                        type="file"
                        id="file"
                        accept=".jpg,.jpeg,.png,.gif" // Restrict file types
                        onChange={handleupload}
                        // capture="filesystem"
                        className={classes.fileUploadButton}
                        style={{ display: 'none' }}
                        disabled={imageData}
                      />
                      <label
                        htmlFor="file"
                        className={classes.uploadFileLabel}
                        disabled={selectedImage}
                      >
                        {!imageData && (
                          <img
                            src={upload}
                            alt="upload Identification number"
                            className={classes.imgPointer}
                          />
                        )}
                        {imageData && (
                          <img
                            src={uploadDisabled}
                            alt="disabled upload Identification number"
                          />
                        )}
                      </label>
                      <button
                        onClick={captureTheimage}
                        className={classes.captureImage}
                        disabled={imageData}
                      >
                        {!imageData && (
                          <img
                            src={captureImg}
                            alt="capture identification number"
                          />
                        )}
                        {imageData && (
                          <img
                            src={CameraCaptureDisabled}
                            alt="camera capture disabled number"
                          />
                        )}
                      </button>
                    </div>
                    {imageData && (
                      <div
                        className={
                          imageData
                            ? classes.selectedImg
                            : classes.unselectedImg
                        }
                      >
                        {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                         */}
                        <button
                          // onClick={imageViewerClick}
                          className={classes.imageViewer}
                          variant="outlined"
                          onClick={handleClickOpen}
                        >
                          <p className={classes.hoverText}>
                            <h2 className={classes.rotaryTitle}>
                              {imageData.name}
                            </h2>
                            <span className={classes.tooltip}>
                              {imageData.name}
                            </span>
                          </p>
                          {/* <p>{imageData.name}</p> */}
                        </button>
                        <button
                          onClick={removeSelectedImage}
                          className={classes.removeImg}
                        >
                          X
                        </button>
                      </div>
                    )}
                    <Dialog
                      open={imageOpen}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <img
                            src={uploadedImg}
                            alt="Thumb"
                            className={classes.imageView}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    <div className={classes.selectedImg}>
                      {imgCheck ? (
                        imgSrc ? (
                          <>
                            {/* <button
                            onClick={removeCaptureImage}
                            className={classes.removecaptureImg}
                          >
                            X
                          </button> */}
                            {/* <img
                            className={classes.captureIMG}
                            src={imgSrc}
                            alt="webcam"
                          /> */}
                            {/* <div className={classes.selectedImg}>
                              <p>CaptureImage.jpeg</p>
                              <button
                                onClick={removeSelectedImage}
                                className={classes.removeImg}
                              >
                                X
                              </button>
                            </div> */}
                          </>
                        ) : (
                          <>
                            {/* <Webcam
                              className={classes.webCam}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              screenshotQuality={0.8}
                              videoConstraints={{
                                ...videoConstraints,
                                facingMode,
                              }}
                            />
                            <button
                              onClick={handleClick}
                              className={classes.switchCamerabtn}
                            >
                              <img
                                src={switchCamera}
                                alt="switchCamera"
                                className={classes.switchCamera}
                              />
                            </button> */}
                            <Dialog
                              fullScreen={fullScreen}
                              open={openDailogue}
                              onClose={handleClose1}
                              aria-labelledby="responsive-dialog-title"
                              className={classes.captureScreen}
                            >
                              <DialogTitle
                                id="responsive-dialog-title"
                                className={classes.dailogHeader}
                              >
                                {'Capture the Image'}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText
                                  className={classes.dailogCont}
                                >
                                  {/* <Webcam
                                      height={200}
                                      width={300}
                                      ref={webcamRef}
                                      screenshotFormat="image/jpeg"
                                      screenshotQuality={0.8}
                                    /> */}
                                  <Webcam
                                    className={classes.webCam}
                                    // height={200}
                                    // width={430}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    screenshotQuality={0.8}
                                    videoConstraints={{
                                      ...videoConstraints,
                                      facingMode,
                                    }}
                                  />
                                  <button
                                    onClick={handleClick}
                                    className={classes.switchCamerabtn}
                                  >
                                    <img
                                      src={switchCamera}
                                      alt="switchCamera"
                                      className={classes.switchCamera}
                                    />
                                  </button>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleClose1}
                                  className={classes.dailogButtonCancel}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className={classes.dailogButton}
                                  onClick={capture}
                                >
                                  Capture
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )
                      ) : null}
                      {/* 
                      <div className={classes.popupActiveButtons}>
                        {imgCheck ? (
                          imgSrc ? null : (
                            <>
                              <button
                                onClick={capture}
                                className={classes.popupActiveButton}
                              >
                                Capture
                              </button>
                              <button
                                onClick={removeCaptureImage}
                                className={classes.cancelButton}
                              >
                                Cancel
                              </button>
                            </>
                          )
                        ) : null}
                      </div> */}
                    </div>
                  </>
                </>
              ) : (
                <div className={classes.MultiDetails_Modal}>
                  <div className={classes.RegisterEquipt_Modal}>
                    {/* {open && <MultiDetails open={open} setOpen={setOpen} />} */}

                    <>
                      <MultiDetails
                        open={open}
                        setOpen={setOpen}
                        equipment={warrayntDATA}
                        key={data?.equipmentNumber}
                        data={warrayntDATA}
                        onDone={onDoneClick}
                        ref={popup}
                        onCancel={onCancelClick}
                      />
                    </>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default InstallationDetails;
