/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import classes from './Input.module.scss';
import classNames from 'classnames';

function DropdownField({
  label,
  name,
  options,
  value,
  placeholder,
  type,
  onChange,
  onClick,
  onBlur,
  errors,
  touched,
  required,
  className: externalclassName = '',
  ...rest
}) {
  const isInputError = touched && errors;
  const styles = {
    formControl: classes.formControl,
    error: 'input-error',
    border: '2px solid red',
  };
  const cx = classNames.bind(styles);

  const classList = cx({
    formControl: true,
    [classes.inputError]: isInputError,
    [externalclassName]: true,
  });

  const handleChange = (event) => {
    onChange(event);
  };
  const handleClick = () => {
    onClick();
    console.warn('Dropdown clicked');
    // Additional logic can go here
  };
  return (
    <div
      className={classNames([classes.formBlock, 'form-control-block', name])}
    >
      {label && (
        <label className={classes.formLabel} htmlFor="input-field">
          {label} {required && <span className={classes.formLabelReq}>*</span>}{' '}
        </label>
      )}
      {/* <label htmlFor={name}>{label}</label> */}

      <select
        id={name}
        type={type}
        name={name}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        className={classList}
        onBlur={onBlur}
        {...rest}
      >
        <option value="">Select an option</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {isInputError && (
        <span className={classNames([classes.errorMsg, 'input-error-mgs'])}>
          {errors}
        </span>
      )}
    </div>
  );
}

export default DropdownField;
