/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';
import classes from './MultiDetails.module.scss';
import closeIcon from '../../../../assets/closeIcon.svg';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material//Hidden';
import { MultiViewDetails } from './MultiViewDetails';
import { toast } from 'react-toastify';
import { ref } from 'yup';
import { isOverflown } from '@mui/x-data-grid/utils/domUtils';
import { validateWarrantyIdentifictionNum } from 'common/customFunctions';
import warrantyStrings from 'common/staticStrings';
const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '42%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '667px',
    height: '730px',
  },

  '@media (orientation: landscape)': {
    // backgroundColor: '#000',
  },

  '@media (min-width:769px) and (min-width:900px)': {
    width: '85%',
    height: '530px',
    overflow: 'auto',
  },
  '@media (min-width:900px)': {
    width: '910px',
    height: '439px',
    overflow: 'auto',
  },
};
const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  padding: '5px 20px 5px 20px ',
  display: 'flex',
  justifyContent: 'space-between',
};
const paraGraph = {
  fontSize: '14px',
  padding: '10px',
};

const MultiDetails = (
  { open, setOpen, equipment: data, onDone, onCancel },
  ref,
) => {
  const [shouldOpen, setSHouldOpne] = useState(open);
  const [selectedData, setSelectedData] = useState();
  const [warrantydata, setWarrantydata] = useState(data);
  const [addBtnCheck, setAddBtnCheck] = useState(false);
  const handleClosePop = () => {
    setSHouldOpne(false);
    onCancel();
  };
  const handleChangeData = (multiData) => {
    const updatedData = warrantydata.map((item) => {
      if (
        item.equipmentNumber.toLowerCase() ===
        multiData.equipmentNumber.toLowerCase()
      ) {
        return multiData;
      } else {
        return item;
      }
    });
    setWarrantydata(updatedData);
  };
  const [nextbtn, setNextBtn] = useState(false);

  useImperativeHandle(ref, () => ({
    showView: showView,
  }));

  function showView(recentData) {
    setWarrantydata(recentData);
    setSHouldOpne(true);
  }
  useEffect(() => {
    const selectedDetails = warrantydata.filter((item) => {
      return item.hasextendedWarranty;
    });
    if (selectedDetails.length > 0) {
      for (let i = 0; i < selectedDetails.length; i++) {
        if (
          selectedDetails[i].image !== undefined &&
          selectedDetails[i].identificationNum !== '' &&
          selectedDetails[i].image !== false
        ) {
          setAddBtnCheck(true);
        } else {
          setAddBtnCheck(false);
        }
      }

      setNextBtn(true);
    } else {
      setNextBtn(false);
    }
    console.warn(warrantydata, 'warrantyData786');
  }, [warrantydata]);
  const onDoneAction = () => {
    const selectedDetails = warrantydata.filter((item) => {
      return item.hasextendedWarranty;
    });
    if (selectedDetails.length > 0) {
      const errorDetails = [];
      setNextBtn(true);
      for (let i = 0; i < selectedDetails.length; i++) {
        console.warn(selectedDetails, 'selectedDetails');
        const equipmentdetails = selectedDetails[i];
        const index = selectedDetails.findIndex((details) => {
          //Excluding current equipment from comparision.
          if (
            details.equipmentId.length > 0 &&
            details.equipmentId.toLowerCase() ===
              equipmentdetails.equipmentId.toLowerCase()
          ) {
            return false;
          }
          if (equipmentdetails.identificationNum && details.identificationNum) {
            return (
              equipmentdetails.identificationNum.toLowerCase() ===
              details.identificationNum.toLowerCase()
            );
          }
          return false;
        });
        const identificationNumberAlreadyUsed = index >= 0;
        if (identificationNumberAlreadyUsed) {
          errorDetails.push(
            warrantyStrings.extendedWarrantyDuplicateIDNum.replace(
              '%#No',
              selectedDetails[i].equipmentNumber,
            ),
          );
        }

        if (
          !validateWarrantyIdentifictionNum(
            selectedDetails[i].identificationNum,
          ) &&
          selectedDetails[i].image === false
        ) {
          // toast.error(
          //   warrantyStrings.extendedWarrantyFormValue.replace(
          //     '%#No',
          //     selectedDetails[i].equipmentNumber,
          //   ),
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   },
          // );
          errorDetails.push(
            warrantyStrings.extendedWarrantyFormValue.replace(
              '%#No',
              selectedDetails[i].equipmentNumber,
            ),
          );
        } else if (
          !validateWarrantyIdentifictionNum(
            selectedDetails[i].identificationNum,
          )
        ) {
          // toast.error(
          //   warrantyStrings.extendedWarrantyValidIDNum.replace(
          //     '%#No',
          //     selectedDetails[i].equipmentNumber,
          //   ),
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   },
          // );
          errorDetails.push(
            warrantyStrings.extendedWarrantyValidIDNum.replace(
              '%#No',
              selectedDetails[i].equipmentNumber,
            ),
          );
        } else if (
          selectedDetails[i].image === false ||
          selectedDetails[i].image === undefined
        ) {
          // toast.error(
          //   warrantyStrings.extendedWarrantyValidImg.replace(
          //     '%#No',
          //     selectedDetails[i].equipmentNumber,
          //   ),
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   },
          // );
          errorDetails.push(
            warrantyStrings.extendedWarrantyValidImg.replace(
              '%#No',
              selectedDetails[i].equipmentNumber,
            ),
          );
        } else {
          // setSelectedData(warrantydata);
          // onDone(warrantydata);
          // setOpen(false);
          // setSHouldOpne(false);
          // return;
        }
      }
      if (errorDetails.length > 0) {
        errorDetails.forEach((itemString) => {
          toast.error(itemString, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
        return;
      } else {
        setSelectedData(warrantydata);
        onDone(warrantydata);
        setOpen(false);
        setSHouldOpne(false);
        return;
      }
      // setOpen(false);
      // setSHouldOpne(false);
      // return;
    } else {
      setNextBtn(false);
      setSelectedData(warrantydata);
      onDone(warrantydata);
      setOpen(false);
      setSHouldOpne(false);
      return;
    }
  };

  return (
    <div className={classes.multiDetailsmodal}>
      <Modal
        open={shouldOpen}
        onClose={handleClosePop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.RegisterEquipt_Modal}>
          <p style={modalTitle}>
            <p className={classes.alignItems}>
              <p>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.textFontSize}
                >
                  Warranty Details
                </Typography>
              </p>
            </p>
            <p className={classes.iconStyles}>
              <Link onClick={handleClosePop} className={classes.closeIcon}>
                <img src={closeIcon} alt="closeIcon" />
              </Link>
            </p>
          </p>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component="div"
            style={paraGraph}
            className={classes.registerNewEquipPopupHead}
          >
            <div className={classes.registerNewEquipPopup}>
              <div className={classes.headingSection}>
                <Hidden mdDown>
                  <div className={classes.productHeader}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={0.5}
                          className={classes.rotaryHeadSec}
                        >
                          {/* <p className={classes.subHead}>Product Name</p> */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className={classes.rotaryHeadSec}
                        >
                          <p className={classes.subHead}>Product Code</p>
                        </Grid>
                        <Grid item xs={6} md={2} sm={3}>
                          <p className={classes.subHead}>Serial Number</p>
                        </Grid>
                        <Grid item xs={6} md={3} sm={3}>
                          <p className={classes.subHead}>
                            Identification Number
                          </p>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <p className={classes.subHead}>
                            Please Upload/Capture warranty certificate
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Hidden>
                {/* {data?.map((uneq, idx) => (
                  <MultiViewDetails />
                ))} */}
                {warrantydata?.map((uneq) => (
                  <>
                    <MultiViewDetails
                      equipment={uneq}
                      key={uneq?.equipmentNumber}
                      onChange={handleChangeData}
                    />
                  </>
                ))}
              </div>
            </div>
          </Typography>
          <div className={classes.popupActiveButtons}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClosePop}
              // onClick={() => {
              //   onDoneAction();
              // }}
              className={classes.cancelButton}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              className={classes.popupActiveButton}
              onClick={() => {
                onDoneAction();
              }}
              // disabled={!addBtnCheck}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default memo(forwardRef(MultiDetails));
